<script setup lang="ts">
// vite only allow folder import using relative
import { type Position } from "node_modules/vue-router/types/router";
import { nextTick, onBeforeMount, onBeforeUnmount, ref, watch } from "vue";
import { Route } from "vue-router";

import CanaryTransition, {
  TransitionStyle,
} from "../transitions/CanaryTransition.vue";
import { injectRouterStore } from "./RouterStore";
import { determineTransitionStyle } from "./utilities";

export interface TransitioningFrom {
  scrollY: number;
  element: HTMLElement;
}

/**
 * @param routeHack Must pass `$route` as a prop to this component to work
 * around issues with reactivity when using Vue 2.7's options API.
 * `useRoute()` works, but is not reactive to changes. The only way we
 * could work out to get reactive changes to the route value was to
 * pass `$route` to this prop directly within the template.
 *
 * This can likely be removed in favor of `useRoute()` when we upgrade to Vue 3.
 */
const props = defineProps<{
  routeHack: Route;
}>();

/**
 * Wrapper around `router-view` component that provides transition
 * animations between pages.
 *
 * Notes:
 * * Should be used in replacement of `router-view` at the top-level.
 * * Nested pages should use `CanaryNestedRouterView`
 */

const emit = defineEmits<{
  (e: "after-enter", args: HTMLElement): void;
  (e: "after-leave", args: HTMLElement): void;
  (e: "before-enter", args: HTMLElement): void;
  (e: "before-leave", args: HTMLElement): void;
  (e: "enter-cancelled", args: unknown): void;
  (e: "enter", args: HTMLElement): void;
  (e: "leave-cancelled", args: unknown): void;
  (e: "leave", args: HTMLElement): void;
  (
    e: "restore-scroll",
    args: { to: Route; from: Route; position: Position | null },
  ): void;
}>();

const routerStore = injectRouterStore();

const transitionStyle = ref(TransitionStyle.NONE);
const transitioningFrom = ref<TransitioningFrom | null>(null);

const onRestoreScrollPosition = (
  to: Route,
  from: Route,
  position: Position | null,
) => {
  // Wait for the DOM to be updated.
  nextTick(() => {
    // This may be called in the middle of the animation if the previous page
    // had a stored scroll position. If so, scroll the window to the saved
    // position and adjust the element being transitioned from accordingly
    // so that it doesn't jump.
    if (transitioningFrom.value && position) {
      document.body.style.minHeight = `${window.innerHeight + position.y}px`;
      const offset = -1 * (transitioningFrom.value.scrollY - position.y);
      transitioningFrom.value.element.style.marginTop = `${offset}px`;
      window.scrollTo(0, position.y);
    }
    emit("restore-scroll", { to, from, position });
  });
};

const onBeforePageLeave = (element: HTMLElement) => {
  // On a navigation, the window will be scrolled to the top immedately after the
  // animation begins, capture the current scroll position and offset the element
  // to fake where the user was scrolled on the page. See `onRestoreScrollPosition`
  // for where `transitioningFrom` is used.
  transitioningFrom.value = { scrollY: window.scrollY, element };
  element.style.marginTop = `-${window.scrollY}px`;

  // Hide the body overflow-x to avoid scrollbars animating alongside the pages.
  document.body.style.overflowX = "hidden";
  emit("before-leave", element);
};

const onAfterLeave = (element: HTMLElement) => {
  transitioningFrom.value = null;
  document.body.style.minHeight = "";
  document.body.style.overflowX = "";
  emit("after-leave", element);
};

watch(
  () => props.routeHack,
  (to, from) => {
    if (!to || !from) {
      return;
    }
    transitionStyle.value = determineTransitionStyle(to, from);
  },
);

onBeforeMount(() => {
  routerStore?.addRestoreScrollPositionCallback(onRestoreScrollPosition);
});

onBeforeUnmount(() => {
  routerStore?.removeRestoreScrollPositionCallback(onRestoreScrollPosition);
});
</script>

<template>
  <CanaryTransition
    :transition-style="transitionStyle"
    @before-enter="$emit('before-enter', $event)"
    @enter="$emit('enter', $event)"
    @after-enter="$emit('after-enter', $event)"
    @enter-cancelled="$emit('enter-cancelled', $event)"
    @before-leave="onBeforePageLeave"
    @leave="$emit('leave', $event)"
    @leave-cancelled="$emit('leave-cancelled', $event)"
    @after-leave="onAfterLeave"
  >
    <router-view v-bind="$attrs" v-on="$listeners" />
  </CanaryTransition>
</template>
