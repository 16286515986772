import { Route } from "vue-router";

import { TransitionStyle } from "../transitions/CanaryTransition.vue";
import { CanaryRoute } from "./CanaryRoute";

export function determineTransitionStyle(
  to: CanaryRoute | Route,
  from: CanaryRoute | Route,
): TransitionStyle {
  // Don't animate the initial load.
  if (from.fullPath == "/") {
    return TransitionStyle.NONE;
  }
  const transitionStyleViaFrom = from?.meta?.transitionFromFactory?.(to, from);
  if (transitionStyleViaFrom) {
    return transitionStyleViaFrom;
  }
  // Use the path depth to determine the animation.
  return getPathSegmentCount(to) < getPathSegmentCount(from)
    ? TransitionStyle.SLIDE_EXIT_BACKWARD
    : TransitionStyle.SLIDE_ENTER_FORWARD;
}

function getPathSegmentCount(route: Route): number {
  // Remove the trailing backslash if one exists.
  return route.path.replace(/\/$/, "").split("/").length;
}
